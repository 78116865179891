export const useMessageStore = defineStore('message-store', {
  
  persist: true,

  state: () => {
    return {
      text: null,
      status: null,
      seconds: null
    }
  },

  actions: {

    clearMessage() {
      this.text = null
      this.status = null
      this.seconds = null
    },

    message(text = `There's been an error`, seconds = 6, status = 'error') {
      this.text = text
      this.status = status
      setTimeout(() => {
        this.clearMessage()
      }, seconds * 1000)
    }

  }
  
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMessageStore, import.meta.hot))
}